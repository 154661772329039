<template>
	<div class="order-create__section">
    <div class="order-create__section-label section-label"
         v-if="FBA.data.Products.length > 0 && _.first(FBA.data.Products).product.id"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['fba_products'])"></div>
      {{ $t('fba_products.localization_value.value') }}
    </div>

    <div class="custom-row"
         v-if="FBA.data.productsForCellArray.length > 0 && _.first(FBA.data.productsForCellArray).data.Product.product.id"
    >
      <div class="custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
           v-for="(item, index) in FBA.data.productsForCellArray"
           :key="index"
      >
        <div class="confirm-product-card">
          <div class="confirm-product-card__name">
            <ValueHelper
                    :value="item.data.Product"
                    :deep="'product.productObject.name_for_fba'"
            />
          </div>

          <div class="confirm-product-card__items">
            <div class="confirm-product-card__item">

              <div class="confirm-product-card__item-name"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_ItemsQty'])"></div>
                {{$t('fbm_ItemsQty.localization_value.value')}}
              </div>
              <div class="confirm-product-card__item-val">
                {{item.data.Product.product.count}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

		<div class="order-create__row custom-row" v-if="FBA.data.sets.length > 0">
			<div class="order-create__col custom-col" v-for="(set, i) in FBA.data.sets"
           :key="i"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fba_set'])"></div>
        <div class="block-table__name"
             v-bind:class="{'mb-4': !isMobileFunc()}"
        >
				  {{$t(`fba_set.localization_value.value`) + '#' + (set.data.countSet)}}
				</div>
        <div class="site-table-wrap table-small"
             v-if="!isMobileFunc()"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fba_name', 'fba_qTy'])"></div>
          <table class="site-table">
            <thead>
            <tr>
              <th>
                {{ $t('fba_name.localization_value.value') }}
              </th>
              <th class="text-lg-right white-space-line">
                {{ $t('fba_qTy.localization_value.value') }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, j) in set.data.productsSet"
                :key="j"
            >
              <td>
                <ValueHelper
                        :value="item.data.Product"
                        :deep="'product.productObject.name_for_fba'"
                />
              </td>
              <td align="right">
                <ValueHelper
                    :value="item.data.Product.product.count"
                />
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="site-table-mobile"
             v-if="isMobileFunc()"
        >
          <div class="site-table-mobile__inner custom-row">
            <div class="custom-col custom-col--50 custom-col--sm-100"
                 v-for="(item, j) in set.data.productsSet"
                 :key="j"
            >
              <div class="site-table-mobile__item">
                <div class="site-table-mobile__head">
                  <ValueHelper
                          :value="item.data.Product"
                          :deep="'product.productObject.name_for_fba'"
                  />
                </div>
                <div class="site-table-mobile__row custom-row">
                  <div class="site-table-mobile__piece custom-col custom-col--33">
                    {{ $t('fba_qTy.localization_value.value') }}
                    <span>
                      <ValueHelper
                              :value="item.data.Product.product.count"
                      />
										</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
			</div>
		</div>

    <div class="order-create__row custom-row" v-if="isWarehouseBuyLabel">
      <div class="order-create__col custom-col">
        <div class="order-create__table block-table">
          <div class="block-table__row">
            <div class="block-table__col block-table__col--20 block-table__col--sm-50 mb-3">
              <div class="block-table__label "
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_Address'])"></div>
                {{$t('fbm_Address.localization_value.value')}}
              </div>
              <div class="block-table__content word-break">
                {{FBA.data.Delivery.delivery.address}}
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_addressSecond'])"></div>
                {{$t('common_addressSecond.localization_value.value')}}
              </div>
              <div class="block-table__content word-break">
                {{FBA.data.Delivery.delivery.address2}}
              </div>
            </div>
            <div class="block-table__col block-table__col--40 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_City'])"></div>
                {{$t('fbm_City.localization_value.value')}}
              </div>
              <div class="block-table__content word-break">
                {{FBA.data.Delivery.delivery.city}}
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_Region'])"></div>
                {{$t('fbm_Region.localization_value.value')}}
              </div>
              <div class="block-table__content word-break">
                {{FBA.data.Delivery.delivery.region}}
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_ZipCode'])"></div>
                {{$t('fbm_ZipCode.localization_value.value')}}
              </div>
              <div class="block-table__content word-break">
                {{FBA.data.Delivery.delivery.zip}}
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_Country', 'express_ViewCustomLimits'])"></div>
                {{$t('fbm_Country.localization_value.value')}}
              </div>
              <div class="block-table__content word-break">
                {{FBA.data.Delivery.delivery.country.label}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


		<div class="order-create__row custom-row">
			<div class="order-create__col custom-col custom-col--50 custom-col--md-100 mb-0">
				<div class="custom-row">
					<div class="custom-col">
						<div class="order-create__section-label section-label"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['fba_addProductToWarehouse'])"></div>
              {{ $t('fba_shippingLabel.localization_value.value') }}
						</div>
						<div class="custom-row product-img-row">
							<div class="order-create__col custom-col"
                   v-if="FBA.data.shippingLabel.data.files.length > 0"
              >
                <ValueHelper
                    :value="_.first(FBA.data.shippingLabel.getFiles()).file.name"
                />
              </div>

              <div class="custom-col custom-col--50 custom-col--md-50 custom-col--sm-100"
                   v-else-if="Object.keys(FBA.data.shippingLabel.data.downloadFiles).length > 0"
              >
                <DownloadFileBlock
                    v-for="(item, index) in FBA.data.shippingLabel.data.downloadFiles"
                    :key="index"
                    :file="item"
                    :optionDelete="false"
                />
              </div>

							<div class="order-create__col custom-col"
                   v-else
              >
                <ValueHelper/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="order-create__col custom-col custom-col--50 custom-col--md-100 mb-0">
				<div class="custom-row product-img-row">
					<div class="custom-col">
						<div class="order-create__section-label section-label"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['fba_addProductToWarehouse'])"></div>
              {{ $t('fba_amazonFNSKU.localization_value.value') }}
						</div>
						<div class="custom-row">
              <div class="order-create__col custom-col"
                   v-if="FBA.data.amazonFNSKU.data.files.length > 0"
              >
                <ValueHelper
                    :value="_.first(FBA.data.amazonFNSKU.getFiles()).file.name"
                />
              </div>

              <div class="custom-col custom-col--50 custom-col--md-50 custom-col--sm-100"
                   v-else-if="Object.keys(FBA.data.amazonFNSKU.data.downloadFiles).length > 0"
              >
                <DownloadFileBlock
                    v-for="(item, index) in FBA.data.amazonFNSKU.data.downloadFiles"
                    :key="index"
                    :file="item"
                    :optionDelete="false"
                />
              </div>

              <div class="order-create__col custom-col"
                   v-else
              >
                <ValueHelper/>
              </div>
						</div>
					</div>
				</div>
			</div>
		</div>



		<div class="order-create__row custom-row">
			<div class="order-create__col custom-col custom-col--md-100 mb-0"
        v-if="showDimensions"
      >
				<div class="custom-row">
					<div class="custom-col">
						<div class="order-create__section-label section-label"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['fba_overallDimensions'])"></div>
              {{ $t('fba_overallDimensions.localization_value.value') }}
						</div>
						<div class="custom-row">
							<div class="order-create__col custom-col">
								<div class="order-create__table block-table">
									<div class="block-table__row">
										<div class="block-table__col block-table__col--fit mb-3">
											<div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate(['common_weightLb'])"></div>
                        {{$t('common_weightLb.localization_value.value')}}
                      </div>
											<div class="block-table__content">
                        <ValueHelper
                            :value="FBA.data.Dimensions.data.weightLB"
                        />
                      </div>
										</div>
										<div class="block-table__col block-table__col--fit mb-3">
											<div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate(['common_weightOz'])"></div>
                        {{$t('common_weightOz.localization_value.value')}}
                      </div>
											<div class="block-table__content">
                        <ValueHelper
                            :value="FBA.data.Dimensions.data.weightOZ"
                        />
                      </div>
										</div>
										<div class="block-table__col block-table__col--fit mb-3">
											<div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate(['common_heightCm'])"></div>
                        {{$t('common_heightCm.localization_value.value')}}
                      </div>
											<div class="block-table__content">
                        <ValueHelper
                            :value="FBA.data.Dimensions.getDimensionsHeight()"
                        />
                      </div>
										</div>
										<div class="block-table__col block-table__col--fit">
											<div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate(['common_lengthCm'])"></div>
                        {{$t('common_lengthCm.localization_value.value')}}
                      </div>
											<div class="block-table__content">
                        <ValueHelper
                            :value="FBA.data.Dimensions.getDimensionsLength()"
                        />
                      </div>
										</div>
										<div class="block-table__col block-table__col--fit">
											<div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate(['common_widthCm'])"></div>
                        {{ $t('common_widthCm.localization_value.value') }}
                      </div>
											<div class="block-table__content">
                        <ValueHelper
                            :value="FBA.data.Dimensions.getDimensionsWidth()"
                        />
                      </div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


      <div class="order-create__col custom-col mb-0" v-if="isWarehouseBuyLabel">
        <div class="order-create__section-label section-label mb-3"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fbm_ShippingPartner'])"></div>
          {{$t('fbm_ShippingPartner.localization_value.value')}}
        </div>

        <div class="order-create__row custom-row">
          <div class="order-create__col custom-col d-flex">
            <RadioLabelPicture
                class="radio-off wfc mb-0"
                :label="FBA.data.shippingPartnerType.name"
                name="'addressType'"
                value="true"
                disabled="true"
                :arrow="true"
            >
              <template slot="ico">
                <img height="11" class="ml-1"
                     v-if="FBA.data.shippingPartnerType.logo_base64"
                     :src="FBA.data.shippingPartnerType.logo_base64" alt="ico">
              </template>
            </RadioLabelPicture>
            <span class="d-flex align-items-center">
            {{FBA.data.shippingPartner.name}}
          </span>
          </div>
  <!--        <div class="order-create__col custom-col d-flex flex-column"-->
  <!--             v-if="FBM.data.shippingPartnerType.signature_price"-->
  <!--             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
  <!--          <div class="admin-edit" @click="editTranslate(['fbm_TheSignFromRecepient','fbm_ServiceFee'])"></div>-->
  <!--          <DefaultCheckbox-->
  <!--              class="block bold"-->
  <!--              :label="$t('fbm_TheSignFromRecepient.localization_value.value')"-->
  <!--              v-model="FBM.data.signFromRecipient"-->
  <!--              :disabled="true"-->
  <!--          />-->

  <!--          <div class="amount-row light pl-4 mt-2"-->
  <!--               v-if="Number(FBM.data.shippingPartnerType.signature_price) > 0"-->
  <!--          >-->
  <!--            {{$t('fbm_ServiceFee.localization_value.value')}}-->
  <!--            &lt;!&ndash;          <span class="ml-1">${{FBM.data.shippingPartnerType.signature_price}}</span>&ndash;&gt;-->
  <!--          </div>-->
  <!--        </div>-->


        </div>
      </div>


			<div class="order-create__col custom-col custom-col--50 custom-col--md-100 mb-0">
				<div class="custom-row">
					<div class="custom-col">
						<div class="order-create__section-label section-label"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['fba_comment'])"></div>
              {{ $t('fba_comment.localization_value.value') }}
						</div>
						<div class="custom-row">
							<div class="order-create__col custom-col">
								<div class="order-create__table block-table">
									<div class="block-table__row">
										<div class="block-table__col">
											<div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate(['common_comment'])"></div>
                        {{ $t('common_comment.localization_value.value') }}
                      </div>
											<div class="block-table__content">
                        <ValueHelper
                            :value="FBA.getComment()"
                        />
                      </div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


			<div class="order-create__col custom-col">
				<div class="custom-row">
					<div class="custom-col">
						<div class="order-create__section-label section-label"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['fba_shipping'])"></div>
              {{ $t('fba_shipping.localization_value.value') }}
						</div>
            <div class="custom-row">
              <div class="order-create__col custom-col">
                <div class="order-create__table block-table">
                  <div class="block-table__row">
                    <div class="block-table__col block-table__col--fit mb-3">
                      <div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate(['fba_skladUsaFee'])"></div>
                        {{ $t('fba_skladUsaFee.localization_value.value') }}
                      </div>
                      <div class="block-table__content">
                        <ValueHelper
                                :value="FBA.data.orderFee"
                        />
                      </div>
                    </div>
                    <div class="block-table__col block-table__col--fit mb-3">
                      <div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate(['fba_packaging'])"></div>
                        {{ $t('fba_packaging.localization_value.value') }}
                      </div>
                      <div class="block-table__content">
                        <ValueHelper
                                :value="FBA.data.packingCost"
                        />
                      </div>
                    </div>
                    <div class="block-table__col block-table__col--fit mb-3">
                      <div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate(['fba_setCost'])"></div>
                        {{ $t('fba_setCost.localization_value.value') }}
                      </div>
                      <div class="block-table__content">
                        <ValueHelper
                                :value="FBA.data.setCost"
                        />
                      </div>
                    </div>
                    <div class="block-table__col block-table__col--fit">
                      <div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate(['fba_shippingDate'])"></div>
                        {{ $t('fba_shippingDate.localization_value.value') }}
                      </div>
                      <div class="block-table__content">
                        <ValueHelper
                                :value="FBA.data.shippingDate"
                                :date="true"
                                :dateType="'DD MMM, YYYY'"
                        />
                      </div>
                    </div>
                    <div class="block-table__col block-table__col--fit">
                      <div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate(['fba_trackingNumber'])"></div>
                        {{ $t('fba_trackingNumber.localization_value.value') }}
                      </div>
                      <div class="block-table__content">
                        <ValueHelper
                                :value="FBA.data.trackingNumber"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import {mixinDetictingMobile} from "../../../../../../mixins/mobileFunctions";
  import ValueHelper from "../../../../../coreComponents/ValueHelper/ValueHelper";
  import DownloadFileBlock from "../../../../../coreComponents/DownloadFileBlock/DownloadFileBlock";
  import {FBAMixin} from "@/mixins/FBAMixins/FBAMixin";
  import RadioLabelPicture from "@/components/UI/radiobuttons/RadioLabelPicture/RadioLabelPicture";

	export default {
		name: "StepFinal",
    components: {
      RadioLabelPicture,
      ValueHelper,
      DownloadFileBlock,
    },

		mixins: [mixinDetictingMobile, FBAMixin],

    props: {
		  FBA: Object,
      showDimensions: {
		    type: Boolean,
        default: false,
      },
      showType: {
		    type: Boolean,
        default: false,
      },
    },
	}
</script>

<style scoped>

</style>
